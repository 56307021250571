<template>
  <div ref="blockRef" class="lg:flex lg:items-center lg:min-h-screen">
    <div class="relative mx-auto w-full max-w-[55rem]">
      <h2
        v-if="block.title"
        :class="`ml-[1%] text-[clamp(2.75rem,0.4643rem+11.4286vw,6.75rem)] leading-none md:ml-0 ${block.title_color.value}`"
        v-html="formatTitle(block.title)"
        data-gsap-target="title"
      />

      <div
        v-if="block.text || block.buttons"
        class="relative z-[1] mx-auto px-4 max-w-[41.25rem] md:mt-14 md:px-0"
      >
        <div
          v-if="block.text"
          class="prose mt-8"
          v-html="block.text"
          data-gsap-target="text"
        />

        <Buttons
          v-if="block.buttons?.length"
          :buttons="block.buttons"
          button-class="mt-8 xl:mt-12"
          data-gsap-target="buttons"
        />
      </div>

      <img
        v-for="(image, index) in block.images"
        :key="image.id"
        :src="image.xs.url"
        :alt="image.xs.alt"
        :class="`absolute rounded-2xl object-cover ${imageClasses[index]}`"
        :width="image.xs.width"
        :height="image.xs.height"
        loading="lazy"
        draggable="false"
        data-gsap-target="image"
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import type { TextImages } from '~/statamic/types/block';

defineProps<{
  block: TextImages;
}>();

let ctx: gsap.Context | null;
const blockRef = ref();

const imageClasses = [
  'hidden lg:block lg:-left-48 lg:bottom-20 lg:aspect-[5/4] lg:w-[15rem]',
  'hidden lg:block lg:-top-24 lg:-right-[22%] lg:aspect-[5/4] lg:w-[14.125rem]',
  'hidden lg:block lg:-right-[8%] lg:-bottom-44 lg:aspect-[20/27] lg:w-[10.75rem]',
];

onMounted(() => {
  ctx = useGsap.context((self) => {
    if (typeof self.selector !== 'function') return;

    const titleLeft = self.selector('[data-gsap-target="title"]')[0];
    const titleRight = self.selector('[data-gsap-target="title"] > .text-right')[0];
    const text = self.selector('[data-gsap-target="text"] > *:first-child')[0];
    const images = self.selector('[data-gsap-target="image"]');
    const buttons = self.selector('[data-gsap-target="buttons"]')[0]?.children;

    // eslint-disable-next-line new-cap
    const { lines: sentences } = new useSplitText(text, { type: 'lines' });

    useScrollTrigger.create({
      pin: isDesktop(),
      start: `top ${isDesktop() ? 'top' : 'bottom'}`,
      end: 'bottom bottom-=25%',
      trigger: blockRef.value,
    });

    const timeline = useGsap.timeline({
      scrollTrigger: {
        start: 'top center',
        trigger: blockRef.value,
      },
    });

    if (titleLeft) {
      timeline.from(titleLeft, {
        x: -100,
        autoAlpha: 0,
      });
    }

    if (titleRight) {
      timeline.from(titleRight, {
        x: 100,
        autoAlpha: 0,
      }, '-=0.25');
    }

    if (sentences) {
      timeline.from(sentences, {
        y: 10,
        stagger: 0.25,
        autoAlpha: 0,
      }, '-=0.5');
    }

    if (buttons?.length) {
      timeline.from(buttons, {
        y: 10,
        stagger: 0.25,
        autoAlpha: 0,
      }, '-=0.75');
    }

    if (images?.length) {
      timeline.from(images, {
        y: 10,
        stagger: 0.25,
        autoAlpha: 0,
      }, '-=1');
    }
  }, blockRef.value);
});

onUnmounted(() => {
  ctx?.revert();
});
</script>
